import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useRouter } from 'apis/history';

import {
  Caption,
  ContainerSmall,
  ContentFrameSmall,
  H2,
  Regular,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import { Image, PrimaryButton, SectionWrapper } from 'components';
import { AppState } from 'state/types';
import { IProduct } from '@kilornd/api-wrapper/dist/types';

import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { green } from 'styles/colors';
import { calculateDiscountProc } from 'utils/prices';

const DownsellInfo: React.FC = React.memo(() => {
  const { goToDownsell } = useRouter();
  const { isTablet } = useQuery();
  const { products } = useSelector((s: AppState) => s.funnel);
  const product = products.find(
    (p: IProduct) => p.key === 'sumefy-12-mo-downsell',
  );

  return (
    <SectionWrapperStyled>
      <ContainerSmall>
        <H2 margin="0 0 1rem" textAlign={!isTablet && 'center'}>
          <TextWrapper color={green}>Wait!</TextWrapper> Grab Your Program With
          a{' '}
          <TextWrapper color={green}>
            {calculateDiscountProc(product).toFixed(0)}% Discount
          </TextWrapper>
        </H2>
        <Regular
          margin={isTablet ? '0 0 1rem' : '0 0 1.5rem'}
          textAlign={!isTablet && 'center'}
        >
          <TextWrapper fontWeight="bold">Act Fast:</TextWrapper> Grab this
          one-time exclusive deal before time runs out. This offer is not
          available anywhere else on this website.
        </Regular>
        <ContentFrameSmall>
          <Caption margin="0 0 1rem" textAlign={!isTablet && 'center'}>
            <TextWrapper fontWeight="bold">75%</TextWrapper> of users who
            started using <TextWrapper fontWeight="bold">Sumefy</TextWrapper>{' '}
            achieved their goals after{' '}
            <TextWrapper fontWeight="bold">28 days.</TextWrapper>
          </Caption>
          <Image margin="0 0 1rem" src="downsell_info_chart" />
          <Caption margin="0 0 1rem">
            Starting something new can be a little overwhelming. To help you get
            started a little easier, we want to give you an extra discount on
            your order.
          </Caption>
          <PrimaryButton onClick={goToDownsell}>Got it</PrimaryButton>
        </ContentFrameSmall>
      </ContainerSmall>
    </SectionWrapperStyled>
  );
});

export default DownsellInfo;

const SectionWrapperStyled = styled(SectionWrapper)`
  min-height: 80vh;

  @media ${mobile} {
    min-height: 75vh;
  }
`;
